import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Service from "./pages/Services/ServiceDetail";
import Kvkk from "./pages/Kvkk/Kvkk";
import Sectors from "./pages/Sectors/Sectors";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/service/:title" element={<Service />} />
        <Route path="/sectors/:title" element={<Sectors />} />
        <Route path="/kvkk" element={<Kvkk />} />
      </Routes>
    </Router>
  );
}

export default App;
