import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import imgbg from "../../assets/img/bmw.jpg";
import { Icon } from "@iconify/react";
import axios from "axios";
import TeklifAlForm from "../../components/TeklifAlForm"; // TeklifAlForm bileşenini dahil ettim

const Sectors = () => {
  const { title } = useParams(); // Route parametresinden sektör başlığını al
  const [sectorData, setSectorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // API çağrısı
    const fetchSectorData = async () => {
      try {
        const response = await axios.get(
          `http://45.11.96.38/api/v1/sectors/getSectorByTitle/${encodeURIComponent(
            title
          )}`
        );
        setSectorData(response.data.data); // Gelen veriyi kaydet
        setLoading(false);
      } catch (err) {
        console.error("Sektör verisi alınırken hata oluştu:", err);
        setError(true);
        setLoading(false);
      }
    };

    fetchSectorData();
  }, [title]);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>Yükleniyor...</p>
      </div>
    );
  }

  if (error || !sectorData) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>Sektör bulunamadı veya bir hata oluştu.</p>
      </div>
    );
  }

  return (
    <>
      <Header />
      {/* Üst Başlık ve Görsel */}
      <section className="relative h-[600px] w-full overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={imgbg}
            alt="foto"
            className="h-full w-full object-cover"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="absolute inset-0 flex items-center">
          <div className="max-w-xl space-y-4 rounded-md bg-[#002B4D] p-8 text-white">
            <h2 className="text-4xl font-bold tracking-tight text-white md:text-5xl">
              {sectorData.title}
            </h2>
            <p className="text-lg leading-relaxed text-gray-200">
              {sectorData.description}
            </p>
          </div>
        </div>
      </section>

      {/* İçerik Bölümü */}
      <section>
        <div className="container mx-auto px-4 py-8 grid md:grid-cols-2 gap-8">
          {/* Sol Kolon - Problemler ve Çözümler */}
          <div className="space-y-8 pl-4">
            {/* Problemler */}
            <section className="space-y-4">
              <div className="flex items-center gap-2 text-blue-900">
                <div className="w-8 h-8 bg-blue-900 text-white rounded-full items-center justify-center">
                  <Icon
                    icon="lsicon:lightning-filled"
                    width="2em"
                    height="2em"
                    style={{ color: "orange" }}
                    className="mr-2"
                  />
                </div>
                <h2 className="text-2xl font-semibold text-blue-900">
                  Sektörün Problemleri
                </h2>
              </div>
              <ul className="space-y-2 text-muted-foreground">
                {sectorData.problems.map((problem, index) => (
                  <li key={index}>• {problem}</li>
                ))}
              </ul>
            </section>

            {/* Çözümler */}
            <section className="space-y-6">
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full border-2 border-blue-900 flex items-center justify-center">
                  <div className="w-4 h-4 rounded-full bg-blue-900" />
                </div>
                <h2 className="text-2xl font-semibold">
                  İhtiyaca Uygun Çözümler
                </h2>
              </div>
              <div className="space-y-6 text-muted-foreground">
                {sectorData.solutions.map((solution, index) => (
                  <div key={index}>
                    <strong>{solution.title}</strong>: {solution.content}
                  </div>
                ))}
              </div>
            </section>
          </div>

          {/* Sağ Kolon - Teklif Al Formu */}
          <div className="flex items-start justify-center lg:justify-end">
            <div className="bg-white shadow-lg rounded-lg w-full max-w-md p-6">
              <h2 className="text-2xl font-bold text-blue-900 mb-6">
                Teklif Al
              </h2>

              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium mb-1 block">
                    Ad Soyad*
                  </label>
                  <input
                    type="text"
                    placeholder="Ad Soyad"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900"
                  />
                </div>

                <div>
                  <label className="text-sm font-medium mb-1 block">
                    E-posta*
                  </label>
                  <input
                    type="email"
                    placeholder="E-posta"
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900"
                  />
                </div>

                <div>
                  <label className="text-sm font-medium mb-1 block">
                    Telefon*
                  </label>
                  <div className="flex gap-2">
                    <select className="w-[100px] border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900">
                      <option value="TR">TR</option>
                      {/* Diğer ülkeler */}
                    </select>
                    <input
                      type="text"
                      placeholder="+90 (555) 000-0000"
                      className="flex-1 border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900"
                    />
                  </div>
                </div>
                <button
                  className="w-full bg-blue-900 text-white text-center py-3 rounded font-semibold hover:bg-blue-800"
                  disabled
                >
                  Bize Ulaşın
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Sectors;
