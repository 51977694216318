import React from "react";
import logo from "../assets/img/mavi.png";

const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-600 py-10">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row md:justify-between gap-8">
          {/* Logo */}
          <div className="flex justify-center md:justify-start">
            <img src={logo} className="w-25 h-10 object-contain" alt="Logo" />
          </div>

          {/* Linkler */}
          <div className="flex flex-col md:flex-row md:space-x-10 gap-8">
            {/* Şirket */}
            <div className="text-center md:text-left">
              <h2 className="font-semibold mb-2">Şirket</h2>
              <ul className="space-y-1">
                <li>
                  <a href="/" className="hover:text-gray-800">
                    Ana Sayfa
                  </a>
                </li>
                <li>
                  <a href="/hizmetlerimiz" className="hover:text-gray-800">
                    Hizmetlerimiz
                  </a>
                </li>
                <li>
                  <a href="/sektörler" className="hover:text-gray-800">
                    Sektörler
                  </a>
                </li>
                <li>
                  <a href="/about" className="hover:text-gray-800">
                    Hakkımızda
                  </a>
                </li>
                <li>
                  <a href="/contact" className="hover:text-gray-800">
                    İletişim
                  </a>
                </li>
                <li>
                  <a href="/kvkk" className="hover:text-gray-800">
                    KVKK
                  </a>
                </li>
              </ul>
            </div>

            {/* İletişim Bilgileri */}
            <div className="text-center md:text-left">
              <h2 className="font-semibold mb-2">İletişim</h2>
              <ul className="space-y-1">
                <li>0224 249 55 99</li>
                <li>0533 668 08 14</li>
                <li>rustuoglu@rustuoglusigorta.com</li>
              </ul>
            </div>

            {/* Adres */}
            <div className="text-center md:text-left">
              <h2 className="font-semibold mb-2">Adres</h2>
              <ul className="space-y-1">
                <li>Fatih Sultan Mehmet Bulv.</li>
                <li>WS Ofis C 19</li>
                <li>Nilüfer, Bursa</li>
              </ul>
            </div>
          </div>

          {/* Lokasyon */}
          <div className="text-center md:text-left">
            <h3 className="font-semibold mb-4">Lokasyon</h3>
            <div className="w-full h-64 md:w-64">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1402.9459166381257!2d28.97769976370429!3d40.218270471596306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca152a3a6ccbc7%3A0x40eb64427a83ca1d!2zUsO8xZ90w7xvxJ9sdSBTaWdvcnRh!5e1!3m2!1str!2str!4v1731536893525!5m2!1str!2str"
                className="w-full h-full"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
