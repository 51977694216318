import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import HeroImage from "../../assets/img/aboutbg.png";
import howWeWorkImage from "../../assets/img/howwework.png";
import { Icon } from "@iconify/react";

const About = () => {
  const values = [
    {
      icon: (
        <Icon
          icon="ic:outline-shield"
          width="2em"
          height="2em"
          style={{ color: "#003366" }}
        />
      ),
      title: "Güvenilir",
    },
    {
      icon: (
        <Icon
          icon="mdi:clock-fast"
          width="2em"
          height="2em"
          style={{ color: "#003366" }}
        />
      ),
      title: "Hızlı",
    },
    {
      icon: (
        <Icon
          icon="lineicons:phone"
          width="2em"
          height="2em"
          style={{ color: "#003366" }}
        />
      ),
      title: "Ulaşılabilir",
    },
  ];

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Hakkımızda Bölümü */}
      <div
        className="relative flex items-center justify-center h-screen w-full min-h-screen"
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="max-w-4xl bg-white/90 backdrop-blur-md p-8 md:p-16 rounded-xl m-4 shadow-lg text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-blue-900 mb-4">
            Hakkımızda
          </h1>
          <p className="text-lg md:text-2xl text-blue-900 mb-6">
            Güvenli Gelecek, Rüştüoğlu Sigorta ile Başlar!
          </p>
          <p className="text-gray-700 text-sm md:text-base leading-relaxed">
            Rüştüoğlu Sigorta, sigorta sektöründe 2000 yılından bu yana dayanan
            deneyimiyle, bireylerin ve işletmelerin ihtiyaçlarını karşılamak
            amacıyla kapsamlı ve güvenilir sigorta çözümleri sunmaktadır.
            Müşteri memnuniyetini öncelikli hedef olarak belirleyen
            ekibimin,geniş ürün yelpazesi ile her türlü riski en iyi şekilde
            yönetmenize yardımcı olur.
          </p>
        </div>
      </div>

      {/* Değerlerimiz Bölümü */}
      <section className="py-12 bg-gray-50">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-10 text-[#003366]">
            Değerlerimiz
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {values.map((item, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="flex items-center gap-4">
                  <div className="bg-blue-50 p-4 rounded-md">{item.icon}</div>
                  <h3 className="text-xl font-semibold text-gray-800">
                    {item.title}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Müşterilerle Çalışma Bölümü */}
      <section className="py-16 bg-blue-100">
        <div className="max-w-6xl mx-auto px-4 grid gap-8 md:grid-cols-2 items-center">
          <div className="overflow-hidden">
            <img
              src={howWeWorkImage}
              alt="Profesyonel takım toplantısı"
              className="w-full h-[500px] md:h-[600px] object-cover"
            />
          </div>

          <div className="space-y-4">
            <h2 className="text-3xl font-bold text-blue-900">
              Müşterilerimizle Nasıl Çalışıyoruz?
            </h2>
            <p className="text-gray-700 leading-relaxed text-sm md:text-base">
              Rüştüoğlu Sigorta olarak, müşteri müşteri memnuniyetini öncelikli
              hedefimiz olarak belirliyoruz. Öncelikle, müşterilerimizin
              ihtiyaçlarını anlamak için onları dikkatle dinliyor, her müşterinin
              farklı gereksinimlerine özel sigorta çözümleri sunarak kişiye özel
              hizmet sağıyoruz. Şeffaf iletişim anlayışımızla poliçeler hakkında
              açık ve anlaşılır bilgiler veriyor bilinçli kararlar almalarına
              yardımcı oluyoruz. 7/24 erişilebilir müşteri destek ekibimizle
              hızlı bir şekilde yanıt almanızı sağlıyoruz. Sürekli olarak
              müşteri geri bildirimini değerlendirerek, hizmet kalitemizi
              arttırmak için çalışıyoruz. Rüştüoğlu sigorta ile güvenilir bir
              partnerle yola çıkmanın ayrıcalığını yaşayacaksınız!
            </p>
            <div className="flex flex-wrap gap-4">
              <div className="flex items-center gap-2">
                <div className="h-2 w-2 rounded-full bg-primary" />
                <p className="text-blue-900 font-medium">7/24 Destek</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-2 w-2 rounded-full bg-primary" />
                <p className="text-blue-900 font-medium">
                  Kişiselleştirilmiş Çözümler
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Ofis ve Harita */}
      <section className="py-12 bg-white">
        <div className="max-w-6xl mx-auto px-4 grid gap-8 md:grid-cols-2 items-center">
          <div className="flex flex-col justify-center items-center text-center md:text-left">
            <h2 className="text-3xl font-bold text-primary mb-4">Ofisimiz</h2>
            <p className="text-gray-700">
              Fatih Sultan Mehmet Bulv. WS Ofis C 19 Nilüfer, Bursa
            </p>
          </div>
          <div className="rounded-lg overflow-hidden shadow-lg">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1402.9459166381257!2d28.97769976370429!3d40.218270471596306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca152a3a6ccbc7%3A0x40eb64427a83ca1d!2zUsO8xZ90w7xvxJ9sdSBTaWdvcnRh!5e1!3m2!1str!2str!4v1731536893525!5m2!1str!2str"
              className="w-full h-64"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default About;
