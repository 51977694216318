import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/img/mavi.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false); // Hizmetlerimiz dropdown için
  const [isSectorsOpen, setIsSectorsOpen] = useState(false); // Sektörler dropdown için
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Mobil menü için
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const sectorsRef = useRef(null);
  const sectorsButtonRef = useRef(null);
  const navigate = useNavigate();

  const menuItems = {
    "Araç Sigortaları": [
      "Trafik Sigortası",
      "Yeşil Kart Sigortası",
      "Kasko Sigortası",
      "Pert Kasko",
      "Tutumlu Kasko",
      "Baby on Board Kasko",
    ],
    "Ev Sigortaları": [
      "Konut Paket Sigortası",
      "Dask-Doğal Afet Sigortası",
      "Ortak Alan-Site Paket Sigortası",
    ],
    "Sağlık Sigortaları": [
      "Tamamlayıcı Sağlık Sigortası",
      "Özel Sağlık Sigortası",
      "Yurtdışı Sağlık Sigortası",
      "Yabancı Uyruklular Olarak Sağlık Sigortası",
    ],
    "Ticari ve Kurumsal Sigortaları": [
      "İş Yeri ve KOBİ Paket Sigortası",
      "Mühendislik Sigortası",
      "Sorumluluk Sigortası",
      "Nakliyat Sigortası",
      "Taşıyıcı Sorumluluk Sigortası",
      "Bina Tamamlama Sigortası",
      "Çevre Kirliliği Sorumluluk Sigortası",
    ],
    "Hayat ve Emeklilik": [
      "Bireysel Emeklilik Sigortası",
      "Otomatik Katılım",
      "Prim İadeli Hayat Sigortası",
      "Uzun Süreli Hayat Sigortası",
      "Azalan Bakiyeli Uzun Süreli Hayat Sigortası",
    ],
    "Finansal Sigortalar": [
      "Devlet Destekli Alacak Sigortası",
      "Kefalet Sigortası",
    ],
    "Özel Ürünler": [
      "Cep Telefonu Sigortası",
      "Tekne Yat Sigortası",
      "Uçak Sigortası",
      "Pati Sigortası",
      "Yurt Dışı Eğitim Sigortası",
      "Güneş Enerji Santralleri Sigortası",
      "Hidroelektrik Enerji Santralleri Sigortası",
      "Bireysel Çatı Tipi Güneş Enerji Paneli Sigortası",
      "Devlet Destekli Tarım Sigortası",
    ],
  };

  const sectors = [
    "Otomotiv",
    "İlaç ve Kimya",
    "Perakendecilik ve Alışveriş Merkezleri",
    "Tekstil",
    "Enerji",
    "İnşaat ve Gayrimenkul",
    "Nakliyat ve Denizcilik",
    "Madencilik",
    "Sanat ve Eğlence",
    "Turizm",
    "Havacılık",
    "Demir Çelik Sanayi",
    "Altyapı ve Gayrimenkul",
    "Tarım ve Hayvancılık",
    "Makine Sanayi",
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Hizmetlerimiz dropdown'u kapat
      if (
        menuRef.current &&
        buttonRef.current &&
        (!menuRef.current.contains(event.target) ||
          menuRef.current === event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }

      // Sektörler dropdown'u kapat
      if (
        sectorsRef.current &&
        sectorsButtonRef.current &&
        (!sectorsRef.current.contains(event.target) ||
          sectorsRef.current === event.target) &&
        !sectorsButtonRef.current.contains(event.target)
      ) {
        setIsSectorsOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
        setIsSectorsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  const handleNavigation = (path) => {
    console.log(path);
    setIsOpen(false);
    const itemPath = `/service`;
    navigate(`/service/${path}`);
  };

  const handleNavigationSector = (path) => {
    console.log(path);
    setIsOpen(false);
    const itemPath = `/sectors`;
    navigate(`/sectors/${path}`);
  };

  return (
    <div className="relative">
      <nav className="sticky top-0 z-50 bg-white bg-opacity-90 shadow-md">
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-8 justify-start">
              <img
                src={logo}
                className="w-25 h-10 object-contain cursor-pointer"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="flex items-center gap-8 justify-end flex-grow">
              <div className="hidden md:flex items-center space-x-6">
                <button
                  onClick={() => navigate("/")}
                  className="text-gray-700 hover:text-blue-900"
                >
                  Ana Sayfa
                </button>
                <button
                  ref={buttonRef}
                  onClick={() => setIsOpen(!isOpen)}
                  className="flex items-center gap-1 text-gray-700 hover:text-blue-900"
                >
                  Hizmetlerimiz
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <button
                  ref={sectorsButtonRef}
                  onClick={() => setIsSectorsOpen(!isSectorsOpen)}
                  className="flex items-center gap-1 text-gray-700 hover:text-blue-900"
                >
                  Sektörler
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                <button
                  onClick={() => navigate("/about")}
                  className="text-gray-700 hover:text-blue-900"
                >
                  Hakkımızda
                </button>
                <button
                  onClick={() => navigate("/contact")}
                  className="text-gray-700 hover:text-blue-900"
                >
                  İletişim
                </button>
              </div>
              {/* Mobil Menü Butonu */}
              <button
                className="md:hidden text-gray-700 hover:text-blue-900"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobil Menü */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <ul className="space-y-4 px-6 py-4">
            <li>
              <button
                onClick={() => navigate("/")}
                className="text-gray-700 hover:text-blue-900"
              >
                Ana Sayfa
              </button>
            </li>
            <li>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-700 hover:text-blue-900"
              >
                Hizmetlerimiz
              </button>
            </li>
            <li>
              <button
                onClick={() => setIsSectorsOpen(!isSectorsOpen)}
                className="text-gray-700 hover:text-blue-900"
              >
                Sektörler
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/about")}
                className="text-gray-700 hover:text-blue-900"
              >
                Hakkımızda
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/contact")}
                className="text-gray-700 hover:text-blue-900"
              >
                İletişim
              </button>
            </li>
          </ul>
        </div>
      )}

      {/* Hizmetler Dropdown */}
      {isOpen && (
        <div
          ref={menuRef}
          className="absolute left-0 right-0 z-50 bg-white shadow-lg"
        >
          <div className="container mx-auto px-4 py-6">
            <div className="grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-auto-fit lg:minmax(200px, 1fr)">
              {Object.entries(menuItems).map(([category, items]) => (
                <div key={category} className="space-y-1 text-left">
                  <h3 className="font-medium text-xs text-blue-900">
                    {category}
                  </h3>
                  <ul className="space-y-1 text-left">
                    {items.map((item) => (
                      <li key={item}>
                        <button
                          onClick={() => handleNavigation(item)}
                          className="text-sm text-left text-gray-700 hover:text-blue-900"
                        >
                          {item}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Sektörler Dropdown */}
      {isSectorsOpen && (
        <div
          ref={sectorsRef}
          className="absolute left-0 right-0 z-50 bg-white shadow-lg"
        >
          <div className="container mx-auto px-4 py-6">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {sectors.map((sector) => (
                <button
                  key={sector}
                  onClick={() => handleNavigationSector(sector)}
                  className="text-sm text-gray-700 hover:text-blue-900 block text-left"
                >
                  {sector}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
