import React, { useState } from "react";
import Header from "../../components/Header";
import contactBanner from "../../assets/img/handshake2.png";
import Footer from "../../components/Footer";
import { Icon } from "@iconify/react";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    tc: "",
    email: "",
    phone: "",
    message: "",
    terms: false,
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    tc: "",
    email: "",
    phone: "",
    message: "",
    terms: "",
  });

  const [kvkkError, setKvkkError] = useState(""); // KVKK uyarısı için state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "phone" && value.length > 10) {
      return; // Eğer telefon numarası 10 karakterden fazla giriliyorsa, işlemi engelle
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (name === "terms" && checked) {
      setKvkkError(""); // KVKK onayı verilirse hata mesajını temizle
    }
  };

  const onSubmit = () => {
    let formErrors = {};

    // İsim kontrolü
    if (formData.firstName.length < 2)
      formErrors.firstName = "İsim en az 2 karakter olmalıdır.";
    else if (/\d/.test(formData.firstName))
      formErrors.firstName = "İsimde rakam bulunamaz.";

    // Soyisim kontrolü
    if (formData.lastName.length < 2)
      formErrors.lastName = "Soyisim en az 2 karakter olmalıdır.";
    else if (/\d/.test(formData.lastName))
      formErrors.lastName = "Soyisimde rakam bulunamaz.";

    // E-posta kontrolü (geçerli formatta mı)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(formData.email))
      formErrors.email = "Lütfen geçerli bir e-posta adresi girin.";

    // Telefon numarası kontrolü
    if (formData.phone.length !== 10)
      formErrors.phone = "Telefon numarası tam 10 haneli olmalıdır."; // Telefon numarası 10 haneli değilse hata ver

    // Mesaj kontrolü
    // if (formData.message.length < 10)
    //   formErrors.message = "Mesaj en az 10 karakter olmalıdır.";

    // KVKK onay kontrolü
    if (!formData.terms) {
      setKvkkError("Lütfen önce KVKK metnini okuduğunuzu onaylayınız.");
      return;
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      console.log(formData);
      setSuccessMessage("Mesajınız başarıyla gönderildi!"); // Set success message
    } else {
      setSuccessMessage(""); // Clear success message if there are errors
    }
  };

  return (
    <div>
      {/* Header */}
      <Header />

      {/* Banner Image */}
      <div className="w-full h-[400px] relative">
        <img
          src={contactBanner}
          alt="İletişim"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center space-y-4">
          <h1 className="mb-6 text-4xl font-bold text-white md:text-5xl">
            İletişim
          </h1>
          <h2 className="mb-2 max-w-2xl text-lg text-white/90">
            Her türlü sorunuz ve sigorta ihtiyaçlarınız için bize
            ulaşabilirsiniz.
          </h2>
          <h2 className="mb-2 max-w-2xl text-lg text-white/90">
            Sizin için buradayız!
          </h2>

          <a
            className="mb-2 max-w-2xl text-lg text-white/90 border-b-2 border-white flex items-center"
            href="tel:+905336680814"
          >
            <Icon
              icon="mdi-light:phone"
              width="2em"
              height="2em"
              style={{ color: "white" }}
              className="mr-2"
            />
            0 (224) 249 55 99
          </a>
          <a
            className="mb-2 max-w-2xl text-lg text-white/90 border-b-2 border-white flex items-center"
            href="https://wa.me/905336680814?text=Merhaba,%20daha%20fazla%20bilgi%20almak%20istiyorum."
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              icon="logos:whatsapp-icon"
              width="2em"
              height="2em"
              style={{ color: "#003366" }}
              className="mr-2"
            />
            0 (533) 668 08 14
          </a>
        </div>
      </div>

      {/* Contact Form and Map Section */}
      <div className="container mx-auto px-4 py-8 flex flex-col lg:flex-row">
        {/* Form Section */}
        <div className="w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
          <div className="mb-8 text-center">
            <h2 className="text-2xl font-bold text-gray-800">
              Bize mail yoluyla da ulaşabilirsiniz
            </h2>
            <p className="text-gray-600 mt-2">
              En kısa sürede size ulaşarak size uygun sigortayı bulalım
            </p>
          </div>

          <form className="space-y-6">
            <div className="p-6 border rounded-lg shadow-lg max-w-lg mx-auto bg-white">
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="flex flex-col">
                  <label htmlFor="firstName" className="mb-2 text-left">
                    Ad
                  </label>
                  <input
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="border p-2 rounded-md shadow-sm w-full"
                    placeholder="Ad"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm">{errors.firstName}</p>
                  )}
                </div>

                <div className="flex flex-col">
                  <label htmlFor="lastName" className="mb-2 text-left">
                    Soyad
                  </label>
                  <input
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="border p-2 rounded-md shadow-sm w-full"
                    placeholder="Soyad"
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm">{errors.lastName}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col mt-4">
                <label htmlFor="email" className="mb-2 text-left">
                  E-posta
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="border p-2 rounded-md shadow-sm w-full"
                  placeholder="ornek@email.com"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div className="flex flex-col mt-4">
                <label htmlFor="phone" className="mb-2 text-left">
                  Telefon Numarası
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  maxLength={10} // Telefon numarasını 10 karakterle sınırlıyoruz
                  className="border p-2 rounded-md shadow-sm w-full"
                  placeholder="Telefon numarası"
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>

              <div className="flex flex-col mt-4">
                <label htmlFor="message" className="mb-2 text-left">
                  Mesaj
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="border p-2 rounded-md shadow-sm w-full"
                  placeholder="Mesaj"
                />
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>

              {/* KVKK Onayı */}
              <div className="mt-4 flex items-center">
                <input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={formData.terms}
                  onChange={handleChange}
                />
                <label htmlFor="terms" className="ml-2 text-sm">
                  <a
                    href="http://localhost:5173/kvkk"
                    className="text-blue-500 hover:underline mr-1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    KVKK
                  </a>
                  metnini okudum ve onaylıyorum.
                </label>
              </div>

              {kvkkError && (
                <p className="text-red-500 text-sm mt-2">{kvkkError}</p>
              )}

              <div className="mt-6 flex justify-center">
                <button
                  type="button"
                  onClick={onSubmit}
                  className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
                >
                  Gönder
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Map Section */}
        <div className="w-full lg:w-1/2 px-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1402.9459166381257!2d28.97769976370429!3d40.218270471596306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ca152a3a6ccbc7%3A0x40eb64427a83ca1d!2zUsO8xZ90w7xvxJ9sdSBTaWdvcnRh!5e1!3m2!1str!2str!4v1731536893525!5m2!1str!2str"
            className="w-full h-[250px] md:h-[300px] lg:h-full"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Contact;
