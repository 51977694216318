import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const Kvkk = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <div className="flex-grow flex flex-col items-center">
        {/* Mavi Alan */}
        <div className="w-full h-80 bg-blue-900 flex justify-center items-center">
          <p className="text-center text-white text-3xl font-semibold">
            Kişisel Verilerin Korunması Kanunu Aydınlatma Metni
          </p>
        </div>

        {/* Bilgilendirme Metni */}
        <div className="w-full px-6 py-8">
          <div className="bg-gray-100 shadow-md rounded-lg p-6">
            <h1 className="text-center text-3xl font-bold text-black mb-6">
              KVKK Aydınlatma Metni
            </h1>
            <h2 className="text-xl font-semibold text-black mb-4">
              Kişisel Verilerin Korunması Hakkında Aydınlatma Metni
            </h2>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line mb-4">
              {`
Kişisel Verilerin Korunması Hakkında Aydınlatma Metni

6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) çerçevesinde, kişisel verilerinizin korunması ve işlenmesi konusunda size bilgi vermek istiyoruz. Kişisel veriler, kimlik bilgileri, iletişim bilgileri, finansal bilgiler, sağlık durumu gibi sizi tanımlayabilecek her türlü veriyi kapsar.

Biz, Rüştüoğlu Sigorta Aracılık Hizmetleri Ltd. Şti. olarak, sigortacılık faaliyetlerimizi yürütürken kişisel verilerinizi belirli amaçlarla işleyebiliriz. Bu metin, verilerinizin nasıl kullanılacağına dair sizi bilgilendirmeyi amaçlamaktadır.
              `}
            </p>

            <h3 className="text-xl font-semibold text-black mb-4">
              Kişisel Verilerinizin Hangi Amaçlarla İşleneceği
            </h3>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line mb-4">
              {`
Kişisel verileriniz, sigortacılık mevzuatı gereği sigorta teklifleri, poliçe işlemleri, prim belirlemeleri, satış ve pazarlama faaliyetleri, hasar ödemeleri gibi süreçlerde kullanılacaktır. Ayrıca, size sunulacak hizmetlerin yerine getirilmesi ve gerekli mevzuat yükümlülüklerinin yerine getirilmesi de bu verilerle sağlanacaktır.
              `}
            </p>

            <h3 className="text-xl font-semibold text-black mb-4">
              Hangi Kişisel Verileriniz İşlenebilir?
            </h3>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line mb-4">
              {`
Bizimle paylaştığınız veya ihtiyaç duyulduğunda işlenecek olan kişisel verileriniz şunlar olabilir:

- Kimlik Verisi: Ad, soyad, doğum tarihi, T.C. kimlik numarası, cinsiyet, medeni durum, vb.
- İletişim Verisi: Telefon, e-posta adresi, ikametgah adresi
- Finansal Veriler: Banka hesap bilgileri, kredi kartı bilgileri
- Özel Nitelikli Veriler: Sağlık durumu, engellilik durumu, dini inançlar, vb.
- Çalışma Verisi: Çalıştığınız şirket, görev, meslek, imza
- Diğer Veriler: Araç bilgileri, sigorta poliçesi bilgileri, seyahat planları, vb.
              `}
            </p>

            <h3 className="text-xl font-semibold text-black mb-4">
              Kişisel Verilerin Kimlere Aktarılabileceği
            </h3>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line mb-4">
              {`
İşlediğimiz kişisel verileriniz, sigorta sözleşmeleri ve ilgili diğer işlemleri gerçekleştirebilmek için sigorta şirketleri, kamu kurumları ve diğer iş ortaklarımıza aktarılabilir. Ayrıca, bazı durumlarda bu veriler, yasal yükümlülüklerin yerine getirilmesi amacıyla ilgili resmi kurumlara iletilmek zorunda kalabilir.
              `}
            </p>

            <h3 className="text-xl font-semibold text-black mb-4">
              Kişisel Verilerin Toplanma Yöntemi ve Hukuki Sebebi
            </h3>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line mb-4">
              {`
Kişisel verileriniz, web sitemiz, mobil uygulamalar, çağrı merkezimiz ve benzeri kanallar aracılığıyla toplanabilir. Bu veriler, sigorta faaliyetlerini yürütmek ve mevzuat yükümlülüklerini yerine getirmek için hukuki gerekçelere dayanarak işlenir.
              `}
            </p>

            <h3 className="text-xl font-semibold text-black mb-4">
              Haklarınız
            </h3>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line mb-4">
              {`
KVKK'ya göre, kişisel verilerinizin işlenip işlenmediğini öğrenme, eksik veya yanlış işlenmiş verilerin düzeltilmesini isteme, verilerinizin silinmesini talep etme gibi haklarınız bulunmaktadır. Ayrıca, otomatik sistemlerle yapılan değerlendirmelere itiraz etme hakkınız da vardır. Bu haklarınızı kullanabilmek için bizimle iletişime geçebilirsiniz.
              `}
            </p>

            <h3 className="text-xl font-semibold text-black mb-4">
              İletişim Bilgilerimiz:
            </h3>
            <p className="text-gray-800 text-lg leading-7 whitespace-pre-line">
              {`
Firma Adı: Rüştüoğlu Sigorta Aracılık Hizmetleri Ltd. Şti.
Adres: İhsaniye Mah. Yüce Sok. WS Ofis C 19 Nilüfer/Bursa
Telefon: 0224 249 55 99
E-posta: rustuoglu@rustuoglusigorta.com
              `}
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Kvkk;
