import React from "react";

const TeklifAlForm = () => {
  return (
    <div className="flex items-start justify-center w-full">
      <div className="bg-white shadow-lg rounded-lg p-6 w-full sm:w-96">
        <h2 className="text-2xl font-bold text-blue-900 mb-6">Teklif Al</h2>

        <div className="space-y-4">
          {/* Ad Soyad Input */}
          <div>
            <label className="text-sm font-medium mb-1 block" htmlFor="name">
              Ad Soyad*
            </label>
            <input
              id="name"
              type="text"
              placeholder="Ad Soyad"
              required
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900"
            />
          </div>

          {/* E-posta Input */}
          <div>
            <label className="text-sm font-medium mb-1 block" htmlFor="email">
              E-posta*
            </label>
            <input
              id="email"
              type="email"
              placeholder="E-posta"
              required
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900"
            />
          </div>

          {/* Telefon Input */}
          <div>
            <label className="text-sm font-medium mb-1 block" htmlFor="phone">
              Telefon*
            </label>
            <input
              id="phone"
              type="tel"
              required
              placeholder="(555) 000-0000"
              className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-900"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Sadece sayılara izin ver
              }}
            />
          </div>

          {/* Submit Button */}
          <button className="w-full bg-blue-900 text-white text-center py-3 rounded font-semibold hover:bg-blue-800">
            Bize Ulaşın
          </button>

          {/* Additional Contact Options */}
          <div className="mt-4 flex gap-6">
            {/* Telefon Butonu */}
            <a
              href="tel:+905336680814"
              className="flex items-center justify-center flex-1 border border-gray-300 text-blue-900 py-1 px-3 rounded text-sm font-medium hover:bg-gray-100"
              aria-label="Telefon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="mr-2"
              >
                <path d="M19.5 22a1.5 1.5 0 0 0 1.5-1.5V17a1.5 1.5 0 0 0-1.5-1.5c-1.17 0-2.32-.18-3.42-.55a1.51 1.51 0 0 0-1.52.37l-1.44 1.44a14.77 14.77 0 0 1-5.89-5.89l1.43-1.43c.41-.39.56-.97.38-1.53c-.36-1.09-.54-2.24-.54-3.41A1.5 1.5 0 0 0 7 3H3.5A1.5 1.5 0 0 0 2 4.5C2 14.15 9.85 22 19.5 22M3.5 4H7a.5.5 0 0 1 .5.5c0 1.28.2 2.53.59 3.72c.05.14.04.34-.12.5L6 10.68c1.65 3.23 4.07 5.65 7.31 7.32l1.95-1.97c.14-.14.33-.18.51-.13c1.2.4 2.45.6 3.73.6a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-.5.5C10.4 21 3 13.6 3 4.5a.5.5 0 0 1 .5-.5" />
              </svg>
            </a>

            {/* WhatsApp Butonu */}
            <a
              href="https://wa.me/905336680814?text=Merhaba,%20daha%20fazla%20bilgi%20almak%20istiyorum."
              target="_blank"
              rel="noopener noreferrer"
              className="flex-1 border border-gray-300 text-blue-900 py-1 px-3 rounded text-sm font-medium hover:bg-gray-100"
              aria-label="Whatsapp"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="ml-6"
              >
                <path
                  fill="currentColor"
                  d="M19.05 4.91A9.82 9.82 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07s.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28"
                />
              </svg>
            </a>

            {/* E-posta Butonu */}
            <a
              href="mailto:rustuoglu@rustuoglusigorta.com"
              className="flex-1 border border-gray-300 text-blue-900 py-1 px-3 rounded text-sm font-medium hover:bg-gray-100"
              aria-label="E-posta"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2em"
                height="2em"
                viewBox="0 0 24 24"
                className="ml-6"
              >
                <path
                  fill="currentColor"
                  d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 5l-8-5zm0 12H4V8l8 5l8-5z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeklifAlForm;
