import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import axios from "axios";
import Bgbg from "../../assets/img/velar.jpg";
import TeklifAlForm from "../../components/TeklifAlForm"; // TeklifAlForm bileşenini dahil ettim

const Service = () => {
  const { title } = useParams(); // Route parametresinden hizmet başlığını al
  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    // API çağrısını yap
    const fetchServiceData = async () => {
      try {
        const response = await axios.get(
          `http://45.11.96.38/api/v1/services/getServiceByTitle/${encodeURIComponent(
            title
          )}`
        );
        setServiceData(response.data.data); // API'den gelen veriyi kaydet
        setLoading(false);
      } catch (err) {
        console.error("Hizmet verisi alınırken hata oluştu:", err);
        setError(true);
        setLoading(false);
      }
    };

    fetchServiceData();
  }, [title]);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>Yükleniyor...</p>
      </div>
    );
  }

  if (error || !serviceData) {
    return (
      <div className="min-h-screen flex justify-center items-center">
        <p>Hizmet bulunamadı veya bir hata oluştu.</p>
      </div>
    );
  }

  return (
    <section className="relative min-h-screen">
      <Header />
      <div
        className="relative bg-cover bg-center"
        style={{
          backgroundImage: `url(${Bgbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Siyah Yarı Saydam Katman */}
        <div className="absolute inset-0 bg-black/40" />

        <div className="relative container mx-auto px-4 py-12">
          <div className="grid gap-8 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1">
            {/* Sol İçerik */}
            <div className="text-white space-y-6 p-6">
              <div className="space-y-2">
                <h2 className="text-2xl font-light">{serviceData.title}</h2>
                <h1 className="text-4xl font-bold">Neleri Kapsıyor?</h1>
              </div>

              <p className="text-gray-200">{serviceData.description}</p>

              <div className="space-y-6">
                {serviceData.subSections.map((section, index) => (
                  <div key={index} className="space-y-2">
                    <h3 className="font-semibold">{section.title}</h3>
                    <ul className="list-disc list-inside space-y-1 text-gray-200">
                      {section.content.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Teklif Al Formu */}
            <div className="flex justify-center items-center w-full">
              <TeklifAlForm /> {/* Teklif formunu burada dahil ettim */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Service;
