import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const InsuranceSection = () => {
  const insuranceData = [
    {
      icon: "heart",
      title: "Tamamlayıcı Sağlık Sigortası",
      description:
        "Kamu sağlık sigortasının kapsamadığı özel hastane hizmetleri ve ilaç masraflarını karşılayan bir poliçedir. Bu sayede, bireylerin sağlık harcamalarını azaltır ve sağlık hizmetlerine erişimi kolaylaştırır.",
    },
    {
      icon: "car",
      title: "Trafik Sigortası",
      description:
        "Motorlu araç sahiplerinin, kazalarda üçüncü şahıslara verebileceği bedensel ve maddi zararları karşılayan zorunlu bir sigorta türüdür. Bu poliçe, kaza sonucunda oluşan tazminat taleplerini karşılayarak araç sahiplerini finansal risklerden korur ve trafik güvenliğini artırmayı amaçlar.",
    },
    {
      icon: "home",
      title: "Konut Sigortası",
      description:
        "Ev sahiplerinin, konutlarında meydana gelebilecek yangın, hırsızlık, doğal afetler gibi risklere karşı korunmasını sağlayan bir poliçedir. Bu sigorta, evin yapısal zararları ve içindeki eşyaların kaybı durumunda tazminat ödemesi yaparak, maddi kayıpları minimize eder.",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold text-center mb-12 text-[#0B3B7B]">
        Hemen Teklif Alın
      </h1>

      {/* Mobilde Swiper, Masaüstünde Grid */}
      <div className="block lg:hidden">
        <Swiper spaceBetween={20} slidesPerView={1} className="py-4">
          {insuranceData.map((item, index) => (
            <SwiperSlide key={index}>
              <InsuranceCard {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="hidden lg:grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {insuranceData.map((item, index) => (
          <InsuranceCard key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

const InsuranceCard = ({ icon, title, description }) => {
  const getIcon = (icon) => {
    switch (icon) {
      case "heart":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ color: "#003366" }}
          >
            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
          </svg>
        );
      case "car":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
            style={{ color: "#003366" }}
          >
            <rect width="24" height="24" fill="none" />
            <g
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            >
              <path d="M19 17h2c.6 0 1-.4 1-1v-3c0-.9-.7-1.7-1.5-1.9C18.7 10.6 16 10 16 10s-1.3-1.4-2.2-2.3c-.5-.4-1.1-.7-1.8-.7H5c-.6 0-1.1.4-1.4.9l-1.4 2.9A3.7 3.7 0 0 0 2 12v4c0 .6.4 1 1 1h2" />
              <circle cx="7" cy="17" r="2" />
              <path d="M9 17h6" />
              <circle cx="17" cy="17" r="2" />
            </g>
          </svg>
        );
      case "home":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ color: "#003366" }}
          >
            <path d="M3 9l9-7 9 7v9a2 2 0 0 1-2 2h-4v-6H7v6H3a2 2 0 0 1-2-2V9z" />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col min-h-[360px]">
      <div className="p-6 flex flex-col items-center text-center space-y-4 flex-grow">
        <div className="w-12 h-12 bg-[#E5EDF9] rounded-lg flex items-center justify-center">
          {getIcon(icon)}
        </div>
        <h2 className="text-xl font-semibold">{title}</h2>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
      <a
        href="mailto:rustuoglu@rustuoglusigorta.com"
        className="mt-4 px-4 py-3 bg-[#0B3B7B] text-white border-2 border-[#0B3B7B] rounded-lg hover:bg-white hover:text-[#0B3B7B] transition duration-300 mb-4 mx-4 sm:mx-auto sm:w-full sm:max-w-[200px] flex items-center justify-center"
      >
        Bize Ulaşın
      </a>
    </div>
  );
};

export default InsuranceSection;
