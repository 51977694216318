import React from "react";
import Hero from "../../components/Hero";
import Header from "../../components/Header";
import Companies from "../../components/Companies"
import InsuranceSection from "../../components/InsuranceSection";
import ExplainUs from "../../components/ExplainUs";
import Footer from "../../components/Footer";


const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Companies />
      <InsuranceSection />
      <ExplainUs />
      <Footer />
    </div>
  );
}

export default Home;