import React from "react";
import handshake from "../assets/img/handshake.png";

const ExplainUs = () => {
  return (
    <section className="w-full mb-4 bg-blue-100">
      <div className="grid grid-cols-1 md:grid-cols-2 items-center">
        {/* Sol Taraf */}
        <div className="px-6 md:px-12 py-8 space-y-6">
          <div className="space-y-2">
            <h2 className="text-3xl md:text-4xl font-bold tracking-tighter text-blue-900">
              Neden
            </h2>
            <h3 className="text-2xl md:text-3xl font-semibold italic text-blue-900">
              Rüştüoğlu Sigorta?
            </h3>
          </div>
          <p className="text-gray-700 leading-relaxed text-base md:text-lg">
            Rüştüoğlu Sigorta, yılların deneyimi ve genç kadrosu ile
            müşterilerine güvenilir ve kapsamlı sigorta çözümleri sunmaktadır.
            Geniş ürün yelpazesi sayesinde bireylerin ve işletmelerin
            ihtiyaçlarına uygun poliçeler sağlamaktadır. Özellikle ikinci
            kuşağın geleceğini güvence altına almayı bir sorumluluk olarak
            görerek, genç nesillerin ihtiyaçlarına yönelik özel çözümler sunar.
            Müşteri memnuniyetine odaklanarak, her aşamada hızlı ve etkili
            destek sunar. Rekabetçi fiyatlarla kaliteli hizmeti bir araya
            getirerek, güvenli bir yaşam için yanınızdadır. Yenilikçi
            yaklaşımlarıyla sektördeki en güncel çözümleri sunarak, siz değerli
            müşterilerinin ihtiyaçlarını en iyi şekilde karşılar. Rüştüoğlu
            Sigorta ile geleceğinizi güvence altına alın!
          </p>
        </div>

        {/* Sağ Taraf */}
        <div className="relative">
          <img
            src={handshake}
            className="w-full h-80 md:h-[520px] object-cover"
            style={{ objectPosition: "top" }}
            alt="Business handshake"
          />
        </div>
      </div>
    </section>
  );
};

export default ExplainUs;
