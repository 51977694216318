import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import AllianzLogo from "../assets/img/allianz-logo.jpg";
import AXALogo from "../assets/img/axa-logo.jpg";
import AKSigortaLogo from "../assets/img/aksigorta-logo.jpg";
import HDILogo from "../assets/img/hdi-logo.jpg";
import SompoLogo from "../assets/img/sompo-logo.jpg";
import RaySigortaLogo from "../assets/img/ray-logo.jpg";
import TurkiyeSigortaLogo from "../assets/img/turkiyesigorta-logo.jpg";
import AnkaraSigortaLogo from "../assets/img/ankarasigorta-logo.jpg";
import MagdeburgerSigortaLogo from "../assets/img/magdeburgersigorta-logo.jpg";
import AnaSigortaLogo from "../assets/img/anasigorta-logo.jpg";
import ZurichSigortaLogo from "../assets/img/zurich-logo.jpg";
import VhvSigortaLogo from "../assets/img/vhvsigorta-logo.jpg";
import GulfSigortaLogo from "../assets/img/gulfsigorta-logo.jpg";
import HdiFibaEmeklilikLogo from "../assets/img/hdi-fiba-logo.png";
import AxaEmeklilikLogo from "../assets/img/axa-emeklilik-logo.png";



const CompanySwiper = () => {
  return (
    <div className="company-swiper-container text-center py-12">
      <h2 className="text-3xl font-bold text-blue-900 mb-6">İş Ortaklarımız</h2>
      <Swiper
        modules={[Autoplay]}
        loop={true}
        autoplay={{ delay: 1500 }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
        }}
      >
        <SwiperSlide>
          <img
            src={AllianzLogo}
            alt="Allianz"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={AXALogo}
            alt="AXA"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={AxaEmeklilikLogo}
            alt="AxaEmeklilikLogo"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={HDILogo}
            alt="HDI"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={AKSigortaLogo}
            alt="AKSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={AnaSigortaLogo}
            alt="AnaSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={AnkaraSigortaLogo}
            alt="AnkaraSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={GulfSigortaLogo}
            alt="GulfSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={MagdeburgerSigortaLogo}
            alt="MagdeburgerSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={RaySigortaLogo}
            alt="RaySigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={HdiFibaEmeklilikLogo}
            alt="HdiFibaEmeklilik"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={SompoLogo}
            alt="Sompo"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={TurkiyeSigortaLogo}
            alt="TurkiyeSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={VhvSigortaLogo}
            alt="VhvSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={ZurichSigortaLogo}
            alt="ZurichSigorta"
            className="mx-auto h-16 lg:h-20 "
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default CompanySwiper;
