import React from "react";
import HeroImage from "../assets/img/mainbg.png"; // Görseli içe aktarın

const Hero = () => {
  return (
    <div
      className="relative flex items-center justify-end bg-cover bg-center h-screen"
      style={{
        backgroundImage: `url(${HeroImage})`,
      }}
    >
      <div className="bg-[#003366] bg-opacity-90 p-6 shadow-lg w-[500px] text-left mt-40">
        <h1 className="text-2xl font-bold text-white">Rüştüoğlu Sigorta</h1>
        <p className="mt-4 text-xl text-white italic">her zaman yanınızda.</p>
        <p className="mt-2 text-base text-white italic">
          Sizi ve sevdiklerinizi güvence altına alacak çözümlerle her adımda
          yanınızdayız.
        </p>
      </div>
    </div>
  );
};

export default Hero;
